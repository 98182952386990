<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div
          class="project-page col-sm-12 mar-top-0"
          style="text-align: center"
        >
          <h2>Hashmask Names</h2>
          <div v-if="loading === true">
            <div class="progress-bar-outer lined thick">
              <div
                class="progress-bar-inner"
                v-bind:style="{ width: progress * 100 + '%' }"
              ></div>
            </div>
          </div>
          <div v-else>
            <div class="project-desc">
              <div class="lined thick inline-block">
                Name
                <div>
                  <input type="text" v-model="search" />
                </div>
              </div>
              <div class="lined thick inline-block">
                Sort
                <div>
                  <select v-model="sortMode">
                    <option :value="0">Index</option>
                    <option :value="1">Ascending</option>
                    <option :value="2">Descending</option>
                  </select>
                </div>
              </div>
            </div>

            <div style="margin-top: 2em; margin-bottom: 1em">
              {{ filtered }} Hashmasks matching this name.
            </div>

            <div class="names-container lined thick inline-block">
              <div v-for="(item, index) in items" :key="item.index">
                <hr v-if="index !== 0" />
                <router-link
                  :to="{ name: 'detail', params: { id: item.index } }"
                >
                  <div class="row">
                    <div class="col-sm-2">#{{ item.index }}</div>
                    <div class="col-sm-10">
                      <div class="name-column">{{ item.name }}</div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Comlink from 'comlink';

import {
  loadHashMaskNames,
  getMasksForNamesPage,
} from '../helpers/database.worker';

let debounce = new Date().getTime();
let debounceTimeout;

export default {
  data() {
    return {
      loading: true,
      progress: 0,
      items: [],
      search: '',
      sortMode: 0,
      filtered: 0,
      limit: 24,
      pageSize: 24,
      selectedPage: 1,
    };
  },
  methods: {
    async updateMaskNames() {
      const offset = (this.selectedPage - 1) * this.pageSize;
      const response = await getMasksForNamesPage({
        search: this.search,
        sortMode: this.sortMode,
        offset,
        limit: this.limit,
      });
      this.items = response.items;
      this.filtered = response.filtered;
    },
    loadUrlParams() {
      this.search = this.$route.query.search || '';
      this.sortMode = Number(this.$route.query.sortMode) || 0;
    },
    writeUrlParams() {
      this.$router.replace({
        name: 'names',
        query: {
          search: this.search || undefined,
          sortMode: this.sortMode || undefined,
        },
      });
    },
    onFilterChange() {
      this.limit = this.pageSize;
      this.offset = 0;
      this.writeUrlParams();
      this.updateMaskNames();
    },
    infiniteScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.limit = this.limit + this.pageSize;
          this.updateMaskNames();
        }
      };
    },
  },
  async mounted() {
    this.loadUrlParams();
    this.items = await loadHashMaskNames(
      Comlink.proxy(({ progress }) => {
        this.progress = progress;
      }),
    ); //
    this.loading = false;
    await this.updateMaskNames();
    this.infiniteScroll();
  },
  watch: {
    sortMode() {
      this.onFilterChange();
    },
    search() {
      const now = new Date().getTime();
      if (now - debounce > 1000) {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
          debounceTimeout = null;
        }
        this.onFilterChange();
      } else if (!debounceTimeout) {
        debounceTimeout = window.setTimeout(() => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
            debounceTimeout = null;
          }
          this.onFilterChange();
        }, 1000);
      }
    },
  },
};
</script>
